
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.text-muted{color: #697077}
.d-none{display:none!important}
.mr-1 {margin-right: 10px;}

i[class*="icon-"] {
  display: inline-block;
  background-position: center;
  background-repeat: no-repeat;
  vertical-align: sub;
  transition: all 0.3s ease;
}
.icon-sound {
  background-image: url(../src/assets/media/icon/sound.svg);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  cursor: pointer;
}
.icon-sound:hover {
  opacity: .8;
}
.icon-sound.disabled {
  opacity: .5;
  pointer-events: none;
}
.icon-plus {
  background-image: url(../src/assets/media/icon/plus.svg);
  width: 18px;
  height: 18px;
}
.icon-delete {
  background-image: url(../src/assets/media/icon/trash_basket.svg);
  width: 18px;
  height: 18px;
}
.icon-sort {
  background-image: url(../src/assets/media/icon/sort.svg);
  width: 18px;
  height: 18px;
}

.modal {
  width: 100%;
  height: 100%;
  background-color: rgba(1, 1, 1, .2);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
}
.modal-content {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  max-width: 540px;
  max-height: 745px;
  border-radius: 8px;
  background: #FFFFFF;
  box-shadow: 0px 0px 16px rgba(105, 112, 119, 0.12), 0px 8px 16px rgba(105, 112, 119, 0.16);
}
.modal-header {
  padding: 24px;
  padding-right: 48px;
  min-height: 72px;
}
.modal-header h2 {
  font-weight: 700;
  font-size: 22px;
  line-height: 24px;
}
.modal-body {
  padding: 24px;
  padding-top: 0;
}
.modal-body h4 {
  color: #111;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}
.modal-close {
  display: block;
  background-image: url(../src/assets/media/icon/close.svg);
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  width: 24px;
  height: 24px;
  top: 24px;
  right: 24px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.modal-back {
  display: block;
  background-image: url(../src/assets/media/icon/arrow-left.svg);
  background-position: center;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.modal-back:hover,
.modal-close:hover {
  opacity: .8;
}

.btn {
  padding: 13px 20px;
  border-radius: 6px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn:hover, .btn:focus {
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btn.disabled, .btn:disabled {
  opacity: 0.65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.switch {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 34px;
  height: 20px;
  outline: none;
  margin-left: 16px;
}
.switch input {
  position: absolute;
  top: -99999px;
  left: -99999px;
}
.switch-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #E4E7EB;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
}
.switch-slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 1px;
  bottom: 1px;
  background-color: #FFFFFF;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}
input:checked + .switch-slider {
  background-color: #4F7FFF;
}
input:checked + .switch-slider:before {
  -webkit-transform: translateX(14px);
  -ms-transform: translateX(14px);
  transform: translateX(14px);
}
.switch-text {
  font-size: 14px;
  line-height: 20px;
  vertical-align: middle;
  display: inline-block;
  margin-right: 10px;
}


.search-input {
  background-color: #DDE1E6;
  border-radius: 8px;
  min-height: 48px;
  opacity: .8;
  background-image: url(../src/assets/media/icon/search.svg);
  background-repeat: no-repeat;
  background-position: top 12px left 16px;
  padding-left: 52px;
  padding-right: 40px;
  position: relative;
}
.search-input input {
  background-color: transparent;
  border: none;
  width: 100%;
  min-height: 48px;
  padding: 12px 0px;
  font-size: 16px;
  font-weight: 400;
}
.search-input .si-canсel {
  background-image: url(../src/assets/media/icon/cancel.svg);
  background-position: center;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  cursor: pointer;
  display: block;
  position: absolute;
  top: 14px;
  right: 14px;
}

.sortby {
  display: inline-block;
  margin-right: 30px;
}
.sortby .icon-sort {
  margin-right: 11px;
  cursor: pointer;
  position: relative;
  top: -2px;
}
.sortby .icon-sort:hover {
  opacity: .8;
}
.sortby .icon-sort.isReverse {
  transform: scaleY(-1);
}
.sortby span {
  color: #697077;
  margin-right: 8px;
}
.sortby select {
  width: auto;
  cursor: pointer;
  padding-right: 22px;
  font-size: 16px;
  border: 1px solid transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url(../src/assets/media/icon/arrow.svg) right no-repeat transparent;
}

.empty-block {
  color: #697077;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 85px;
}
.empty-block img {
  margin-bottom: 24px;
}
.empty-block h3 {
  font-weight: 400;
  font-size: 32px;
  line-height: 42px;
  margin-bottom: 16px;
}
.empty-block h4 {
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 12px;
}
.empty-block h6 {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}
