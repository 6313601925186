@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;900&display=swap');

* {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
  font-family: Inter, sans-serif;
  /* background-color: #F8F9FB; */
  /*-webkit-font-smoothing: antialiased;*/
}

html {
  width: 100%;
  height: 100%;
}

body {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0;
  overflow: hidden;
}

#root {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #f8f9fb;
}
.container {
  max-width: 1442px;
  width: 100%;
  margin: 0 auto;
  padding: 0 48px;
}

.dnd-hovered.words-block {
  border: 1px solid #4F7FFF;
}

.dnd-hovered.dnd-hover {
  border: 1px solid #4F7FFF !important;
}

.drag-teacher {
   background-color: rgba(136, 92, 255, 0.1);
   border: 1px solid #885CFF;
   color: #885CFF;
   opacity: .48;
 }

.drag-student {
   background-color: #DDE1E6;
   border: 1px solid rgba(105, 112, 119, 0.4);
   opacity: .48;
 }

.active {
  font-weight: bold!important;
}

@media (max-width: 1140px) {
  .container {
    padding: 0 24px;
  }
}

@media (max-width: 720px) {
  .container {
    padding: 0 16px;
  }
}
